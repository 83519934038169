<template>
  <div class="ma-auto">
    <v-btn class="pa-10 woo-button" :class="{ 'v-btn--active': this.active }" >
      <span class="ma-auto image-text-wrapper"
      ><v-img
          class="woo-image"
          src="@/assets/images/woo_logo.png"
          contain
      ></v-img>
        <div class="text-inside-button">WooCommerce</div></span
      ></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "WooButton",
  props: ["active"],
};
</script>

<style scoped>
.woo-button {
  width: 294px;
  height: 52px;
  padding: 12px 47px;
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  background-color: #ffffff !important;
  border-radius: 10px;
}
.image-text-wrapper{
  width: 100%;
}
.woo-image {
  float: left;
  width: 25px;
  height: 29px;
  object-fit: cover;
  -o-object-fit: cover;
}
.text-inside-button{
  width: 109px;
  height: 19px;
  float: left;
  line-height: 30px;
  letter-spacing: 0;
  margin-right: 10px;
}
</style>