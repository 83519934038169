<template>
    <v-container class="container-max-width">
      <div class="syncio-logo-background"></div>
      <v-row class="sign-in-wrapper">
        <v-col offset="5" cols="6">
          <v-row>
            <v-col cols="12">
              <h2 class="text-h2 navy-blue">Sign In</h2>
            </v-col>
          </v-row>
          <v-row col="12">
            <v-col class="text-h6 subheading"> Select Platform </v-col>
          </v-row>
          <v-row class="buttons-margin">
            <v-col>
              <ShopifyButton @click.native="navigateToShopifyAdmin" :active="this.shopifyButtonClicked"/>
            </v-col>

            <v-col>
              <WooButton @click.native="enableForm" :active="!this.formDisabled" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-4">
              <div v-show="errors">
                <v-alert v-for="(value, key) in errors" v-bind:key="key" class="error rounded" transition="fade-transition" dismissible>
                  <span class="white--text">{{ value }}</span>
                </v-alert>
              </div>
            </v-col>
          </v-row>
          <v-row class="sign-in-card-wrapper">
            <v-col cols="12">
              <v-card class="pa-16 rounded-lg" v-if="shopifyButtonClicked">
                <v-row>
                  <v-col cols="12" class="text-center">
                    <p class="text-body-1 pa-10">Redirecting you to Login Via Shopify</p>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="pl-9 pr-9 rounded-lg" v-else :disabled="formDisabled">
                <v-col cols="12" class="pt-10">
                  <v-text-field
                    clearable
                    outlined
                    label="E-mail Address"
                    type="email"
                    class="rounded-0"
                    v-model="email"
                    @change="$v.email.$touch()"
                    :error-messages="checkEmailErrors"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    clearable
                    outlined
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    class="rounded-0 mt-n4"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    hint="Passwords must contain at least 8 letters and/or numbers"
                    v-model="password"
                    @change="$v.password.$touch()"
                    :error-messages="checkPasswordErrors"
                  >
                  </v-text-field>
                </v-col>
                <v-row class="mt-n6">
                  <v-col class="ml-5">
                    <v-checkbox
                      label="Remember Me"
                      class="rounded"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="mt-4">
                    <a class="text-body-1 float-right blue--text"
                      >Forgot password?</a
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-btn
                block
                class="primary sign-in-button"
                :class="{'disable-events': this.$v.$invalid}"
                @click="loginSubmit(email, password)"
                >
                <v-progress-circular color="white" indeterminate v-if="this.isLoading"></v-progress-circular>
                <span v-else>Sign In</span>
              </v-btn
              ></v-col
            >
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" class="text-center">
              <p class="text-body-1 text-no-wrap">Don't have Syncio yet? <router-link to="/registration">Sign Up</router-link> for free.</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import ShopifyButton from "@/views/components/ShopifyButton.vue";
import WooButton from "@/views/components/WooButton.vue";
import { required, email } from 'vuelidate/lib/validators'
import {LOGIN, REMOVE_ERRORS} from "@/store/actions.type";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: { ShopifyButton, WooButton },
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      formDisabled: true,
      shopifyButtonClicked: false
    };
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  created() {
    this.$store.dispatch(`auth/${REMOVE_ERRORS}`);
  },
  computed: {
    ...mapGetters("auth", ["errors", "isLoading"]),
    checkEmailErrors () {
      const emailErrors = [];
      if (!this.$v.email.$dirty) return emailErrors
      !this.$v.email.email && emailErrors.push('Must be valid e-mail')
      !this.$v.email.required && emailErrors.push('E-mail is required')
      return emailErrors
    },
    checkPasswordErrors () {
      const passwordErrors = []
      if (!this.$v.password.$dirty) return passwordErrors
      !this.$v.password.required && passwordErrors.push('Password is required')
      return passwordErrors
    },
  },
  methods: {
    enableForm() {
      this.shopifyButtonClicked = false;
      this.formDisabled = false;
      this.$forceUpdate()
    },
    navigateToShopifyAdmin(){
      this.shopifyButtonClicked = true;
      this.formDisabled = true;
      this.$forceUpdate()

    },
    loginSubmit(email, password){
      this.$v.$touch();
      if (!this.$v.$invalid){
        this.loadingLogin = true;
        this.$store.dispatch(`auth/${LOGIN}`, {email, password})
            .then(() => {
              this.$router.push({name: "DashboardPage"})
            })
            .catch((error) => {
              console.log(error);
              this.isLoading = false;
            });
      }
    }
  }
};
</script>

<style scoped>
.container-max-width{
  max-width: 1200px;
  min-width: 768px !important;
}
.syncio-logo-background {
  position: absolute;
  background-image: url(~@/assets/images/logo.svg);
  background-repeat: no-repeat;
  opacity: 0.2;
  margin-top: 2%;
  height: 90%;
  width: 30%;
  left: -5%;
  background-size: contain;
  background-position: 50% 50%;
}
.syncio-logo {
  position: absolute;
  height: 100%;
  width: 100%;
}
.sign-in-wrapper {
  margin-top: 5%;
}
.navy-blue {
  color: #0e3b4d;
}
.subheading {
  margin-top: 5.6%;
  text-transform: uppercase !important;
}
.buttons-margin{
  margin-top: 1.7%;
}
.sign-in-card-wrapper{
  margin-top: 2.3%;
}
.sign-in-button {
  text-transform: capitalize;
  height: 43px !important;
  color: white;
}
.disable-events {
  opacity: 0.5;
  pointer-events: none
}
</style>